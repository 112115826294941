@import 'mixins';

.container {
  position: fixed;
  z-index: 110;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(45, 45, 45, 0.75);
  font-size: 16px;
  backdrop-filter: blur(5px);

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
    padding: 18px;

    @media (max-width: 576px) {
      padding: 0;
    }
  }
  .modal {
    width: 100%;
    max-width: 500px;
    position: relative;
    background-color: white;
    border-radius: 12px;
    padding: 32px;
    box-sizing: border-box;
    color: var(--color-text-100);
    text-align: center;
    margin-bottom: auto;

    .modalTitle {
      font-size: 1.8rem;
      font-weight: 700;
      padding: 4px 0 18px 0;
      text-align: center;
    }
    .modalContent {
      display: block;
    }
    .modalButton {
      @include button-blue;

      padding: 18px 12px;
      margin-top: 12px;
    }

    @media (max-width: 992px) {
      padding: 18px;
    }
    @media (max-width: 576px) {
      max-width: 100%;
      border-radius: 12px 12px 0 0;
      margin-bottom: 0;

      &::after {
        content: '';
        position: fixed;
        height: 100vh;
        left: 0;
        right: 0;
        top: 100%;
        background-color: white;
      }
    }
  }
  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    width: 100%;
    max-width: 460px;
    cursor: pointer;
    background: none;
    border: none;
    color: white;
    margin: 0;
    opacity: 0.65;
    margin-top: auto;
    transition: all 200ms;
    padding: 8px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
      opacity: 1;
    }

    @media (max-width: 576px) {
      max-width: 100%;
    }
  }

  @media (max-width: 576px) {
    padding: 0;
    justify-content: flex-end;
  }
}
